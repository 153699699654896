import React, { useEffect, useState } from "react"
import ReactCanvasConfetti from "react-canvas-confetti"
import { gsap } from "gsap"
import styled from "styled-components"
import { ScrollTrigger } from "gsap/ScrollTrigger"

// function randomInRange(min, max) {
//   return Math.random() * (max - min) + min
// }
gsap.registerPlugin(ScrollTrigger)

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
}

const Fire = () => {
  const [fire, setFire] = useState(false)
  const [reset, setReset] = useState(false)

  const onClickFire = () => {
    setFire(!fire)
  }

  const onClickReset = () => {
    setReset(!reset)
  }

  const onFire = () => {
    // console.log("do something after fire")
    setFire(false)
    setReset(false)
  }

  const onReset = () => {
    setFire(false)
    setReset(false)
    // console.log("do something after reset")
  }

  const onDecay = () => {
    setFire(false)
    setReset(false)
    // console.log("do something after animation")
  }

  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#trigger",
          markers: false,
          start: "top 35%",
          toggleActions: "play none none none",
        },
      })
      .call(onClickFire, null, 1)
  }, [])

  return (
    <DivStyled id="trigger">
      {/* <div>
        <button onClick={onClickFire}>Fire</button>
        <button onClick={onClickReset}>Reset</button>
      </div> */}
      <div className="mycanvas">
        <ReactCanvasConfetti
          fire={fire}
          reset={reset}
          onFire={onFire}
          onDecay={onDecay}
          onReset={onReset}
          style={canvasStyles}
          particleCount={500}
          angle={360}
          spread={360}
        />
      </div>
    </DivStyled>
  )
}

const DivStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 10rem;
`

export default Fire
