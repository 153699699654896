import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import styled from "styled-components"
import HeroImage from "./HeroImage"
import { Btn1Clear, Btn1White } from "../../../styles/helpers"

const Hero = ({ data }) => {
  const bgImg = data.heroImage
  const imageDisplay = getImage(
    data.heroLogo.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.heroLogo.altText
  const btnOneType = data.buttonOneType
  const btnTwoType = data.buttonTwoType
  let btnOneDisplay
  let btnTwoDisplay

  btnOneType === "internal"
    ? (btnOneDisplay = (
        <Link to={`/${data.buttonOneSlug}`}>{data.buttonOneText}</Link>
      ))
    : btnOneType === "external"
    ? (btnOneDisplay = (
        <a target="_blank" rel="noreferrer" href={`${data.buttonOneUrl}`}>
          {data.buttonOneText}
        </a>
      ))
    : btnOneType === "phone"
    ? (btnOneDisplay = (
        <a href={`tel:1+${data.buttonOneUrl}`}>{data.buttonOneText}</a>
      ))
    : (btnOneDisplay = null)

  btnTwoType === "internal"
    ? (btnTwoDisplay = (
        <Link to={`/${data.buttonTwoSlug}`}>{data.buttonTwoText}</Link>
      ))
    : btnTwoType === "external"
    ? (btnTwoDisplay = (
        <a target="_blank" rel="noreferrer" href={`${data.buttonTwoUrl}`}>
          {data.buttonTwoText}
        </a>
      ))
    : btnTwoType === "phone"
    ? (btnTwoDisplay = (
        <a href={`tel:1+${data.buttonTwoUrl}`}>{data.buttonTwoText}</a>
      ))
    : (btnTwoDisplay = null)

  return (
    <StyledSection>
      <HeroImage bgImg={bgImg} />
      <div className="hero-content">
        <div className="hero-content__logo">
          <GatsbyImage
            image={imageDisplay}
            alt={imageAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div className="hero-content__buttons">
          {btnOneDisplay !== null && btnOneDisplay}
          {btnTwoDisplay !== null && btnTwoDisplay}
        </div>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50rem;

  @media (min-width: 768px) {
    height: 60rem;
  }

  @media (min-width: 1025px) {
    height: 67.5rem;
  }

  .hero-content {
    position: relative;
    width: 100%;
    max-width: 75rem;
    z-index: 100;

    &__logo {
      max-width: 30rem;
      margin: auto auto 5rem;

      @media (min-width: 768px) {
        max-width: 100%;
      }
    }
    &__buttons {
      width: 100%;
      text-align: center;

      a {
        display: block !important;
        max-width: 30rem;
        margin: auto;

        @media (min-width: 768px) {
          display: inline-block !important;
        }
      }

      a:first-of-type {
        ${Btn1Clear};
        margin-bottom: 4rem;

        @media (min-width: 768px) {
          margin-right: 2rem;
          margin-bottom: 0;
        }
      }

      a:last-of-type {
        ${Btn1White};
      }
    }
  }
`

export default Hero
