import React, { useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import {
  B2LightBlue,
  colors,
  H1White,
  medWrapper,
} from "../../../styles/helpers"

import BackgroundOne from "../../Icons/BackgroundOne"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const DrMo = ({ data }) => {
  const imageDisplay = getImage(
    data.drMoIcon.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.drMoIcon.altText
  const isReversed = data.drMoReverse

  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `#dr-mo-trigger`,
          markers: false,
          start: "top 100%",
          end: "+=1500",
          scrub: 1,
          toggleActions: "play none none none",
        },
      })
      .fromTo(
        `#dr-mo-trigger .icon__bg-one`,
        {
          x: 15,
          y: 40,
        },
        {
          x: -15,
          y: -40,
        }
      )
  }, [])

  return (
    <SectionStyled reversed={isReversed}>
      <div id="dr-mo-trigger" className="wrapper">
        <div className="wrapper-inner">
          <div className="content">
            <div className="content__title">
              <h2>{data.drMoTitle}</h2>
            </div>
            <div
              className="content__paragraphs"
              dangerouslySetInnerHTML={{
                __html: data.drMoContent,
              }}
            />
          </div>
          <div className="icon">
            <div className="icon__inner">
              <GatsbyImage
                image={imageDisplay}
                alt={imageAlt}
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
              />
            </div>
            <div className="icon__bg-one">
              <BackgroundOne />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-color" />
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  position: relative;
  margin-top: 10rem;
  margin-bottom: 10rem;

  .wrapper {
  }

  .wrapper-inner {
    ${medWrapper};
    ${props =>
      props.reversed ? "flex-direction: row-reverse" : "flex-direction: row"};
  }

  .content {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(60%);
    }

    h2 {
      ${H1White};
      display: inline-block;
      padding: 0 2rem;
      background-color: ${colors.colorPrimary};
    }
    &__paragraphs {
      max-width: 70rem;

      p {
        ${B2LightBlue};
      }
    }
  }

  .icon {
    position: relative;
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(40%);
    }

    &__inner {
      position: relative;
      max-width: 37.5rem;
      margin: 5rem auto 0;
      padding: 0 5rem;
      z-index: 10;

      @media (min-width: 768px) {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    }

    &__bg-one {
      position: absolute;
      right: 0;
      top: 0rem;
      left: 0;
      max-width: 40rem;
      margin: auto;
      z-index: 5;

      @media (min-width: 768px) {
        top: -5rem;
      }
    }
  }

  .bg-color {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${colors.colorTertiary};
    opacity: 0.6;
    z-index: -1;

    @media (min-width: 768px) {
      ${props => (props.reversed ? "left: 25%" : "right: 30%")};
    }
  }
`

export default DrMo
